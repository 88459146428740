import React, { useState } from 'react'
import OurWorkLogo from '../assets/images/PeaceTrainSoundingLouder.svg'
import TeamMemberModal from "../components/modals/TeamMemberModal"

const AboutTheTeam = ({ data }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedMember, setSelectedMember] = useState({});
    const closeModal = () => {
        setModalOpen(false);
    };

    return (
        <section className="about-the-team">
            { modalOpen && <TeamMemberModal member={selectedMember} closeModal={closeModal} modalOpen={modalOpen} />}
            <h2>about the team</h2>
            <div className="contents">
                <div>
                    <article>
                        {data.LeftContent}
                    </article>
                    <img src={OurWorkLogo} alt="Sounding Louder" />
                </div>
                <div>
                    <ul>
                        {data.Team && data.Team.length > 0 && data.Team.map(member => (
                            <li>
                                <img onClick={() => { setSelectedMember(member); setModalOpen(true) }} src={`${member.ProfilePicture.localFile.publicURL}`} width={203} height={203} alt={member.FullName} />
                                <p>{member.FullName}</p>
                                <span>{member.Title}</span>
                            </li>
                        ))}
                    </ul>
                    {data.Trustees && data.Trustees.length > 0 &&
                        <div>
                            <h3>trustees</h3>
                            <ul>
                                {data.Trustees.map(member => (
                                    <li>
                                        <img src={`${member.ProfilePicture.localFile.publicURL}`} width={203} height={203} alt={member.FullName} />
                                        <p>{member.FullName}</p>
                                        <span>{member.Title}</span>
                                        
                                    </li>
                                ))}
                            </ul>
                        </div>
                    }
                </div>
            </div>
        </section>
    )
}

export default AboutTheTeam