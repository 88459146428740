import React from "react"
import MainLayout from "../../layouts/MainLayout"
import TrainBackground from "../../assets/images/about-us-train.svg"
import TrainMobileBackground from "../../assets/images/about-us-mobile-train.svg"
import OurWork from "../../assets/images/about-us-sounding-louder.svg"
import FeedingImage from "../../assets/images/FeedingCircle1.png"
import PeaceTrainingImage from "../../assets/images/PeaceTrainingCircle1.png"
import PlaygroundImage from "../../assets/images/PlaygroundCircle1.png"
import SafewaterImage from "../../assets/images/SafewaterCircle1.png"
import PeaceBuildersImage from "../../assets/images/AboutPeaceBuilders.png"
import YusufCatStevens from "../../assets/images/yusuf-cat-stevens.png"
import CatStevensLogo from "../../assets/images/cs-logo.png"
import SEO from "../../components/SEO"
import seoContents from "../../constants/seo-contents"

import { Link } from "gatsby"
import AboutTheTeam from "../../components/AboutTheTeam"

const AboutUs = ({ data }) => {
  return (
    <MainLayout>
      <SEO title={`${seoContents.aboutUs.title}`} description={`${seoContents.aboutUs.description}`} />
      <section className="about-us-section-1">
        <div className="about-us-first-section">
          <h2>Peace Train is an initiative close to my heart. </h2>
          <article>
            <p>
              I have always been dedicated to charitable work and Peace Train
              gives me the perfect vehicle to ensure we can continue to help
              those disadvantaged around the world.
            </p>
          </article>
          <img src={CatStevensLogo} alt="Cat Stevens Logo" width="166px" />
        </div>
        <div className="about-us-second-section">
          <img src={YusufCatStevens} alt="Yusuf Cat Stevens" width="100%" />
        </div>
      </section>
      <section className="about-us-section-2">
        <div>
          <article>
            <h2>vision</h2>
            <p>the world as one</p>
          </article>
          <article>
            <h2>values</h2>
            <p>concern for others</p>
          </article>
          <article>
            <h2>mission</h2>
            <p>
              feed the hungry
              <br />- spread peace
            </p>
          </article>
        </div>
        <img className="desktop-img" alt="Train" src={TrainBackground} width="100%" />
        <img className="mobile-img" alt="Train Mobile" src={TrainMobileBackground} width="100%" />
      </section>
      {/* <section>
        <AboutTheTeam data={data.strapiAboutTheTeam} />
      </section> */}
      <section className="about-us-section-3">
        <div className="about-us-section-3-center">
          <img src={OurWork} alt="Sounding Louder" className="soundinglouder" />
          <nav>
            <Link to="/our-work/feeding">
              <span>feeding</span>
            </Link>
            <img src={FeedingImage} className="nav-image" alt="feeding" />
            <Link to="/our-work/safe-water">
              <span>safe water</span>
            </Link>
            <img src={SafewaterImage} className="nav-image" alt="safe water" />
            <Link to="/our-work/playgrounds">
              <span>playgrounds</span>
            </Link>
            <img src={PlaygroundImage} className="nav-image" alt="playgrounds" />
            <Link to="/our-work/peace-training">
              <span>peace training</span>
            </Link>
            <img src={PeaceTrainingImage} className="nav-image" alt="peace training" />
            <Link to="/our-work/peace-partners">
              <span>peace partners</span>
            </Link>
            <img src={PeaceBuildersImage} className="nav-image" alt="playgrounds" />
          </nav>
        </div>
      </section>
    </MainLayout>
  )
}


export default AboutUs;

export const pageQuery = graphql`  
  query {
    strapiAboutTheTeam {
      LeftContent
      Team {
        FullName
        ProfilePicture {
          localFile{
            publicURL
          }
        }
        ModalPicture {
          localFile{
            publicURL
          }
        }
        Title
        ModalContent
      }
    }
  }
`