import { Link } from 'gatsby';
import React from 'react';
import Modal from 'react-modal';
import closeIcon from '../../assets/images/close-24px-black.svg'
import backgroundImage from '../../assets/images/aboutTheTeamModalBackground.svg'

Modal.setAppElement(`#___gatsby`);
const modalStyles = {
    overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.58)",
        zIndex: 4
    },
    content: {
        position: "relative",
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "77.65625%",
        height: "75%",
        maxWidth: "994px",
        maxHeight: "553px",
        border: 0,
        background: '#ffffff'
    },
};

export default function TeamMemberModal(props) {
    const modalCloseTimeout = 300;
    return (
        <Modal
            isOpen={props.modalOpen}
            onRequestClose={props.closeModal}
            style={modalStyles}
            contentLabel="Modal"
            closeTimeoutMS={modalCloseTimeout}
        >
            <img style={{
                zIndex: -1,
                width: "77.65625%",
                position: 'absolute',
                left: '7.5%',
                top: '25%'
            }}
                src={backgroundImage}
                alt="backgroundImage"
            />
            <div style={{
                display: 'flex',
                padding: '6%'
            }}>
                <Link
                    to="/"
                    aria-label="close modal"
                    style={{
                        width: "39px",
                        height: "39px",
                        position: "absolute",
                        right: 0,
                        top: 0,
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        props.closeModal();
                    }}
                >
                    <img src={closeIcon} alt="closeIcon" />
                </Link>
                <div className="team-member">
                    <div className="first-column">
                        <img src={props.member.ModalPicture.localFile.publicURL} alt={props.member.FullName} width={203} height={203} />
                    </div>
                    <div className="second-column">
                        <section>
                            <p>{props.member.FullName}</p>
                            <span>{props.member.Title}</span>
                        </section>
                        <br />
                        <article>
                            <p>{props.member.ModalContent}</p>
                        </article>
                    </div>
                </div>
            </div>
        </Modal>
    )
};